@import '_variables.scss';
.categorySnippetContainer {
    margin: 0px 15px 10px 15px;
}
.splitIntroSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    @media only screen and (min-width: $tabletBreakpoint) and (max-width: $desktopBreakpoint) {
        padding: 0 15px;
    }

    .introContainer {
        margin-top: 20px;
        width: 100%;

        @media only screen and (min-width: $tabletBreakpoint) {
            max-width: 777px;
        }
        @media only screen and (min-width: $desktopBreakpoint) {
            max-width: 645px;
        }
        @media only screen and (min-width: $largeDesktopBreakpoint) {
            max-width: 810px;
        }
    }

    .introContent {
        padding: 0px 15px 0px 20px;
        margin-bottom: 0px;
        color: var(--color-000-ccc);
    }

    .introTitle {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 27px;
        line-height: 45px;
        color: var(--color-000-fff);
        padding: 0;
    }
}

/* IntroText snippet */
.introContainer,
.categoriesIntroContainer {
    font-family: 'Roboto Condensed', sans-serif;
    background-color: var(--color-fff-121212);
    // margin-bottom: 50px;
    border: 2px solid var(--color-CCC-1a1a1a);
    border-radius: 4px;

    .introVisible.addPaddingBottom {
        padding-bottom: 0px;
    }
    .introVisible {
        padding: 10px 5px 10px 20px;
        display: flex;
        align-items: center;
        margin: 8px 0;
        background: none;
        width: 100%;
        justify-content: space-between;
        cursor: pointer;
        &.listIntroTitle {
            padding: 10px 5px 10px 20px;
        }

        .introTitle {
            font-family: 'Roboto Condensed', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 21px;
            line-height: 25px;
            color: var(--color-000-fff);
            width: 90%;
            text-align: left;
        }
        .toggle {
            width: 10%;
            background-color: var(--color-388713-419d16);
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            border-radius: 50%;
            margin-right: 5px;
            height: 22px;
            width: 22px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            .minus {
                margin-top: -4px;
                margin-left: -1px;
            }
        }
    }
    .introContent.marginTop {
        margin-top: 20px;
        padding-bottom: 0px;
    }
    .introContent {
        width: calc(100% - 20px);
        display: none;
        position: relative;
        padding: 0px 15px 15px 20px;
        overflow-y: scroll;
        margin-bottom: 20px;
        color: var(--color-000-fff);

        ol,
        ul {
            margin-left: 15px;

            li::marker {
                font-size: 14px;
                color: black; /* Change the color */
                display: inline-block; /* Needed to add space between the bullet and the text */
            }
        }
        ul {
            list-style-type: disc;
        }

        &::-webkit-scrollbar {
            width: 8px;
            margin-left: 30px;
            border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: var(--color-388713-419d16);
        }
        &::-webkit-scrollbar-track {
            background-color: var(--color-CCC-1a1a1a);
            border-radius: 4px;
        }
        &.isOpen {
            width: calc(100% - 17px);
            display: block;
            max-height: 100px;
        }
        .introHeading,
        h2 {
            font-family: Roboto Condensed;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;

            margin-top: 15px;
        }
        h3 {
            font-family: Roboto Condensed;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;

            margin-top: 15px;
        }
        h4,
        h5,
        h6 {
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 24px */
        }

        .introParagraph,
        p {
            font-family: Arial;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 18px;
            text-align: justify;

            margin-top: 6px;
            a {
                color: var(--color-2f72eb-4b85ee);
            }
            a.introLink {
                color: var(--color-388713-419d16);
            }
        }
    }
}
/*End of intro text snippet*/
@media only screen and (min-width: $largeDesktopBreakpoint) {
    .introContainer {
        width: 50%;

        &.isOpen {
            max-height: 160px;
        }
    }
    .introContainer {
        .introVisible {
            .introTitle {
                font-size: 24px;
                line-height: 28px;
            }
        }
        .introContent {
            .introHeading,
            h2 {
                font-size: 18px;
                line-height: 21px;
            }
            .introParagraph,
            p {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
    .categorySnippetContainer {
        margin: 0px 0 30px 0;
        background: var(--color-f5f5f5-1a1a1a);
        border-radius: 6px;
        padding: 30px 20px;
        display: flex;
        justify-content: center;
        .categoriesIntroContainer {
            width: 60%;
        }
        .introWrapper {
            width: 100%;
        }
        .introContainer {
            margin-bottom: 0px;
        }
    }
}
@media only screen and (min-width: $desktopBreakpoint) and (max-width: $largeDesktopBreakpoint) {
    .introContainer {
        width: 90%;

        &.isOpen {
            max-height: 160px;
        }
    }
    /* IntroText snippet */
    .introContainer {
        .introVisible {
            .introTitle {
                font-size: 24px;
                line-height: 28px;
            }
        }
        .introContent {
            .introHeading,
            h2 {
                font-size: 18px;
                line-height: 21px;
            }
            .introParagraph,
            p {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}

@media only screen and (min-width: $tabletBreakpoint) and (max-width: $desktopBreakpoint) {
    .categorySnippetContainer {
        margin: 0px 50px 10px 50px;
    }
}
@media only screen and (min-width: $desktopBreakpoint) and (max-width: $largeDesktopBreakpoint) {
    .categorySnippetContainer {
        margin: 0px 0 30px 0;
        background: var(--color-f5f5f5-1a1a1a);
        border-radius: 6px;
        padding: 30px 20px;
        display: flex;
        justify-content: center;
        .categoriesIntroContainer {
            width: 80%;
        }
        .introContainer {
            margin-bottom: 0px;
        }
        .introTitle {
            font-size: 30px;
            font-weight: bold;
        }
    }
}
