@import '../common-scss/_variables.scss';

.listPage {
    padding-top: 0;

    .dealsTitle {
        color: var(--color-000-fff);
        text-align: center;
        font-family: 'Roboto Condensed';
        font-size: 27px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }
    .filters {
        margin: 30px 15px 0 15px;
    }

    .listIntro {
        margin: 15px 15px 45px 15px;
        border-radius: 9px;
    }
    .listIntro.noMarginBottom {
        margin-bottom: 0;
    }
}
.searchPagePadding {
    padding-top: 0;
}

.noResult {
    margin: 10vw 6vw;
    a {
        cursor: pointer;
    }
}

.skeletonLoader {
    width: 100%;
    height: 100vh;
    background-color: #f3f3f3;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    margin-top:20px;
}

.skeletonLoader::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
    animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}

@media only screen and (max-width: $mobileBreakpoint) {
    .listPage {
        padding-top: 0;

        .filters {
            display: flex;
            flex-direction: column;
            margin: 30px 15px 0 15px;
        }
    }
}

@media only screen and (min-width: $desktopBreakpoint) {
    .listPage {
        padding-top: 0;
        .filters {
            margin: 24px 15px 0 15px;
        }

        .listIntro {
            display: flex;
            justify-content: center;
            background-color: var(--color-f5f5f5-1a1a1a);
            padding: 20px 0;
            margin: 0 0 45px 0;
        }
    }
    .searchPagePadding {
        padding-top: 0;
    }
}

@media only screen and (max-width: $tabletBreakpoint) {
    .giveOpacity {
        position: fixed;
        transition: 0.1s ease-in-out;
        z-index: 4;
        right: 0;
        bottom: 0;
        width: 100%;
        height: calc(100vh - 50px);
        background: rgba(0, 0, 0, 0.5803921568627451);
    }
}
@media only screen and (min-width: $tabletBreakpoint) and (max-width: $desktopBreakpoint) {
    .listPage .listIntro {
        margin: 0 25px 45px 25px;
    }
}
